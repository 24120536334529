/*===== VARIABLES CSS =====*/
/*=== COLORS ===*/
:root {
  --first-color: #0396a6;
  --dark-color: #1d2130;
  --white-color: #fefdfc;
  --background-white-color: #fefdfc;
  --background-dark-color: #000000;
  --background-dark-green-color: #677865;
  --background-green-color: #cdcdc5;
  --background-gray-color: #eeeeee;
  --text-gay-color: #525560;
  --text-white-color: #ffffff;
  --test-red-color: red;
}

/*=== Fuente y tipografia ===*/
:root {
  --body-font: 'Roboto', sans-serif;
  --biggest-font-size: 4rem;
  --h1-font-size: 56px;
  --h2-font-size: 3rem;
  --h3-font-size: 2rem;
  --h4-font-size: 1.25rem;
  --normal-font-size: 1rem;
}

@media screen and (min-width: 768px) {
  :root {
    --biggest-font-size: 4rem;
    --h1-font-size: 2.5rem;
    --h2-font-size: 2.5rem;
    --h3-font-size: 1.8rem;
    --normal-font-size: 1rem;
  }
}

.sliderContainerAllActivities {
  padding: 1rem;
  margin-left: auto;
  margin-right: auto;
  width: 100%;
  max-width: 350px;
}

.carouselCardAllActivities {
  width: 100%;
  padding: 16px;
  box-sizing: border-box;
}

.carouselImageWrapperAllActivities {
  position: relative;
  width: 100%;
  max-width: 300px;
  height: 300px;
  overflow: hidden;
  border-radius: 20px;
}

.carouselImageAllActivities {
  width: 100%;
  height: 100%;
  background-size: cover;
  background-position: center;
  transition: all 0.3s;
}

.carouselImageBlurAllActivities {
  filter: blur(4px);
}

.carouselOverlayAllActivities {
  position: absolute;
  inset: 0;
  display: flex;
  flex-direction: column;
  justify-content: end;
  padding: 16px;
  transition: opacity 0.3s;
  z-index: 2;
}

.carouselTitleAllActivities {
  color: var(--text-white-color);
  font-size: var(--h3-font-size);
  font-weight: bold;
}

.carouselDescriptionAllActivities {
  color: var(--text-white-color);
  margin-top: 8px;
}

.carouselButtonAllActivities {
  margin-top: 16px;
  background-color: var(--white-color);
  color: var(--dark-color);
  padding: 8px 16px;
  border-radius: 4px;
  opacity: 0;
  transition: opacity 0.3s;
}

.carouselButtonVisibleAllActivities {
  opacity: 1;
}

.carouselButtonVisibleAllActivities:hover {
  color: var(--white-color);
  background-color: var(--background-dark-green-color);
  transition: color 0.3s ease;
}

.slick-prev,
.slick-next {
  background-color: transparent; /* Altere para a cor desejada */
  border-radius: 50%; /* Ajuste o formato do botão */
  width: 40px; /* Ajuste o tamanho do botão */
  height: 40px; /* Ajuste o tamanho do botão */
}

.slick-prev::before,
.slick-next::before {
  font-size: 24px; /* Ajuste o tamanho do ícone */
  color: var(--background-dark-green-color); /* Cor do ícone */
}

@media screen and (min-width: 760px) {
  .sliderContainerAllActivities {
    max-width: 1010px;
  }
  .carouselCardAllActivities {
    width: 50%;
  }
}

@media screen and (min-width: 1200px) {
  .sliderContainerAllActivities {
    max-width: 1280px;
  }
  .carouselImageWrapperAllActivities {
    max-width: 400px;
    height: 400px;
  }
  .carouselCardAllActivities {
    width: 33.3333%;
  }
}
