/*===== VARIABLES CSS =====*/
/*=== COLORS ===*/
:root {
  --first-color: #0396a6;
  --dark-color: #1d2130;
  --white-color: #fefdfc;
  --background-white-color: #fefdfc;
  --background-dark-color: #000000;
  --background-dark-green-color: #677865;
  --background-green-color: #cdcdc5;
  --background-gray-color: #eeeeee;
  --text-gay-color: #525560;
  --text-white-color: #ffffff;
  --test-red-color: red;
}

/*=== Fuente y tipografia ===*/
:root {
  --body-font: 'Roboto', sans-serif;
  --biggest-font-size: 4rem;
  --h1-font-size: 56px;
  --h2-font-size: 48px;
  --h3-font-size: 2rem;
  --h4-font-size: 1.25rem;
  --normal-font-size: 1rem;
}

@media screen and (min-width: 768px) {
  :root {
    --biggest-font-size: 4rem;
    --h1-font-size: 2.5rem;
    --h2-font-size: 2.5rem;
    --h3-font-size: 1.8rem;
    --normal-font-size: 1rem;
  }
}

/*=== z index ===*/
:root {
  --z-back: -10;
  --z-normal: 1;
  --z-tooltip: 10;
  --z-fixed: 100;
  --z-modal: 1000;
}

/*===== SECTION ===== */
.lSectionMinistries {
  background-color: var(--background-dark-color);
}
.sectionMinistries {
  height: 720px;
  width: 100%;
  max-width: 1279px;
  margin: 0 auto;
  padding: 2rem 0;
  color: var(--text-white-color);
  display: grid;
  justify-content: center;
  align-items: center;
}
.textContentMinistries {
  padding: 0px;
}
.sectionTitleMinistries {
  font-size: var(--h3-font-size);
  color: var(--text-white-color);
  margin-bottom: 1rem;
  font-weight: bold;
  display: flex;
  justify-content: center;
  align-items: center;
}
.sectionTextMinistries {
  text-transform: uppercase;
  max-width: 100%;
  width: 300px;
  color: var(--text-white-color);
  display: flex;
  justify-content: center;
  text-align: center;
  opacity: 60%;
  margin-bottom: 5px;
}
.imageContainerMinistries {
  position: relative;
  display: grid;
  align-items: center;
  justify-content: center;
}
.sectionImgMinistriesFirst,
.sectionImgMinistriesLast {
  width: 320px;
  height: 320px;
  border-radius: 50%;
}
.sectionImgMinistriesFirst {
  z-index: var(--z-normal);
}
.sectionImgMinistriesLast {
  z-index: var(--z-tooltip);
  transform: translateY(-35%);
}

/*===== MEDIA QUERIES =====*/
@media screen and (min-width: 760px) {
  .sectionMinistries {
    height: max(100vh, fit-content);
    display: grid;
    flex-direction: row;
    justify-content: center;
    align-items: center;
  }
  .textContentMinistries {
    padding: 10px;
  }
  .sectionTitleMinistries {
    font-size: var(--h2-font-size);
  }
  .sectionTextMinistries {
    text-align: center;
    margin-bottom: 0px;
    width: 100%;
  }
  .imageContainerMinistries {
    display: flex;
  }
  .sectionImgMinistriesFirst,
  .sectionImgMinistriesLast {
    width: 450px;
    height: 450px;
  }
  .sectionImgMinistriesFirst {
    transform: translateX(25%);
  }
  .sectionImgMinistriesLast {
    transform: translateX(-25%);
  }
}

@media screen and (min-width: 1200px) {
  .bd-grid {
    margin-left: auto;
    margin-right: auto;
  }
  .sectionMinistries {
    display: flex;
  }
  .sectionTextMinistries {
    text-align: start;
    margin-bottom: 0px;
    width: 252px;
  }
  .textContentMinistries {
    padding: 0px;
  }
  .sectionTitleMinistries {
    display: flex;
    justify-content: start;
    align-items: start;
    font-size: 48px;
  }
  .imageContainerMinistries {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .sectionImgMinistriesFirst,
  .sectionImgMinistriesLast {
    width: 500px;
    height: 500px;
  }
  .sectionImgMinistriesFirst {
    transform: translateX(9%);
  }
  .sectionImgMinistriesLast {
    transform: translateX(-35%);
  }
}
