/*===== VARIABLES CSS =====*/
/*=== COLORS ===*/
:root {
  --first-color: #0396a6;
  --dark-color: #1d2130;
  --white-color: #fefdfc;
  --background-white-color: #fefdfc;
  --background-dark-color: #000000;
  --background-dark-green-color: #677865;
  --background-green-color: #cdcdc5;
  --background-gray-color: #eeeeee;
  --text-gay-color: #525560;
  --text-white-color: #ffffff;
  --test-red-color: red;
}
/*=== Fuente y tipografia ===*/
:root {
  --body-font: 'Roboto', sans-serif;
  --biggest-font-size: 4rem;
  --h2-font-size: 3rem;
  --h3-font-size: 1.125rem;
  --normal-font-size: 1rem;
}

@media screen and (min-width: 768px) {
  :root {
    --biggest-font-size: 4rem;
    --h2-font-size: 2.5rem;
    --normal-font-size: 1rem;
  }
}

/*===== SECTION ===== */
.lSectionPlanYourVisit {
  background-color: var(--background-green-color);
  display: flex;
  justify-content: center;
  align-items: center;
  /* height: 715px; */
  padding: 10px;
}
.containerPlanYourVisit {
  width: 100%;
  max-width: 1279px;
  margin-left: 0;
  margin-right: 0;
  display: row;
}
.sectionPlanYourVisitData {
  margin-bottom: 3rem;
  width: 100%;
}
.flexContentPlanYourVisit {
  display: grid;
}
.mlflexContentPlanYourVisit {
  margin-left: 0px;
}
.columInfoPlanYourVisit {
  width: 100%;
}
.minTitlePlanYourVisit {
  font-weight: bold;
  font-size: var(--h4-font-size);
}
.textCustomPlanYourVisit {
  color: var(--text-gay-color);
  max-width: 727px;
  margin-bottom: 10px;
}
.hoverColorFacebookPlanYourVisit,
.hoverColorInstagramPlanYourVisit,
.hoverColorYoutubePlanYourVisit,
.hoverColorWhatsappPlanYourVisit {
  color: var(--dark-color);
  transition: color 0.3s ease;
}
.hoverColorFacebookPlanYourVisit:hover {
  color: #0866ff;
  transition: color 0.3s ease;
}
.hoverColorInstagramPlanYourVisit:hover {
  color: rgb(251, 173, 80);
  transition: color 0.3s ease;
}
.hoverColorYoutubePlanYourVisit:hover {
  color: #f70000;
  transition: color 0.3s ease;
}
.hoverColorWhatsappPlanYourVisit:hover {
  color: #22c35e;
  transition: color 0.3s ease;
}

/*===== MEDIA QUERIES =====*/

@media screen and (min-width: 320px) {
  .lSectionPlanYourVisit {
    /* background-color: yellow; */
  }
  .containerPlanYourVisit {
    margin-top: 90px;
    /* background-color: green; */
  }
  .divContainerPlanYourVisit {
    width: 100%;
    /* background-color: red; */
  }
}

@media screen and (min-width: 480px) {
  .containerPlanYourVisit {
    margin-top: 80px;
    /* background-color: green; */
  }
  .divContainerPlanYourVisit {
    width: 100%;
    /* background-color: pink; */
  }
}

@media screen and (min-width: 760px) {
  .lSectionPlanYourVisit {
    /* background-color: pink; */
  }
  .containerPlanYourVisit {
    margin-top: 80px;
    /* background-color: green; */
  }
  .sectionColumPlanYourVisit {
    display: grid;
    grid-template-columns: repeat(1, minmax(0, 1fr));
    gap: 1rem;
  }
  .flexContentPlanYourVisit {
    display: flex;
  }
  .minTitlePlanYourVisit {
    font-size: var(--h3-font-size);
  }
  .textCustomPlanYourVisit {
    margin-bottom: 15px;
  }
  .mlflexContentPlanYourVisit {
    margin-left: 1rem;
  }
  .divContainerPlanYourVisit {
    width: 700px;
  }
}

@media screen and (min-width: 1024px) {
  .lSectionPlanYourVisit {
    /* background-color: pink; */
  }
  .containerPlanYourVisit {
    margin-top: 80px;
    /* background-color: green; */
  }
  .divContainerPlanYourVisit {
    width: 100%;
    /* background-color: yellow; */
  }
}

@media screen and (min-width: 1200px) {
  .lSectionPlanYourVisit {
    /* background-color: pink; */
  }
  .containerPlanYourVisit {
    margin-top: 90px;
    /* background-color: green; */
  }
  .divContainerPlanYourVisit {
    width: 500px;
    /* background-color: purple; */
  }
  .sectionColumPlanYourVisit {
    grid-template-columns: repeat(2, minmax(0, 1fr));
    gap: 1rem;
  }
  .flexContentPlanYourVisit {
    display: flex;
  }
  .minTitlePlanYourVisit {
    font-size: var(--h3-font-size);
  }
  .textCustomPlanYourVisit {
    margin-bottom: 20px;
  }
  .mlflexContentPlanYourVisit {
    margin-left: 1rem;
  }
}

@media screen and (min-width: 1920px) {
  .lSectionPlanYourVisit {
    /* background-color: pink; */
  }
  .containerPlanYourVisit {
    margin-top: 80px;
    /* background-color: red; */
  }
  .divContainerPlanYourVisit {
    width: 550px;
    /* background-color: green; */
  }
}
