/*===== VARIABLES CSS =====*/
/*=== COLORS ===*/
:root {
  --first-color: #0396a6;
  --dark-color: #1d2130;
  --white-color: #fefdfc;
  --background-white-color: #fefdfc;
  --background-dark-color: #000000;
  --background-dark-green-color: #677865;
  --background-green-color: #cdcdc5;
  --background-gray-color: #eeeeee;
  --text-gay-color: #525560;
  --text-white-color: #ffffff;
  --test-red-color: red;
}

/*=== Fuente y tipografia ===*/
:root {
  --body-font: 'Roboto', sans-serif;
  --biggest-font-size: 4rem;
  --h1-font-size: 56px;
  --h2-font-size: 3rem;
  --h3-font-size: 2rem;
  --h4-font-size: 1.25rem;
  --normal-font-size: 1rem;
}

@media screen and (min-width: 768px) {
  :root {
    --biggest-font-size: 4rem;
    --h1-font-size: 2.5rem;
    --h2-font-size: 2.5rem;
    --h3-font-size: 1.8rem;
    --normal-font-size: 1rem;
  }
}

.windowOverviewAndImpactTabs {
  width: 350px;
  height: 480px;
  background: var(--background-white-color);
  overflow: hidden;
  display: flex;
  flex-direction: column;
  margin-top: 10px;
}
.navOverviewAndImpactTabs {
  background: var(--background-white-color);
  padding: 5px 5px 0;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
  border-bottom: 1px solid #eeeeee;
  height: 44px;
}
.mainOverviewAndImpactTabs {
  display: flex;
  justify-content: start;
  flex-grow: 1;
  user-select: none;
}
.ulOverviewAndImpactTabs,
.liStylesOverviewAndImpactTabs {
  list-style: none;
  padding: 0;
  margin: 0;
  font-weight: 500;
  font-size: 16px;
}
.ulOverviewAndImpactTabs {
  display: flex;
  width: 100%;
}
.liStylesOverviewAndImpactTabs {
  border-radius: 5px;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
  width: 100%;
  padding: 10px 15px;
  position: relative;
  background: var(--background-white-color);
  cursor: pointer;
  height: 24px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex: 1;
  min-width: 0;
  position: relative;
  user-select: none;
}
.underlineOverviewAndImpactTabs {
  position: absolute;
  bottom: -1px;
  left: 0;
  right: 0;
  height: 1px;
  background: var(--background-green-color);
}
.liSelectedwindowOverviewAndImpactTabs {
  background: var(--background-white-color);
}
.liStylesOverviewAndImpactTabs button {
  width: 20px;
  height: 20px;
  border: 0;
  background: var(--background-white-color);
  border-radius: 3px;
  display: flex;
  justify-content: center;
  align-items: center;
  stroke: #000;
  margin-left: 10px;
  cursor: pointer;
  flex-shrink: 0;
}
.textOverviewAndImpactTabs {
  font-size: 16px;
  color: var(--text-gay-color);
  padding: 10px;
}

/*===== MEDIA QUERIES =====*/

@media screen and (min-width: 320px) {
  .windowOverviewAndImpactTabs {
    width: 592px;
    height: 550px;
    margin-top: 0px;
    /* background-color: red; */
  }
}

@media screen and (min-width: 480px) {
  .windowOverviewAndImpactTabs {
    width: 592px;
    height: 350px;
    margin-top: 0px;
    /* background-color: pink; */
  }
}

@media screen and (min-width: 760px) {
  .windowOverviewAndImpactTabs {
    width: 740px;
    height: 300px;
    margin-top: 0px;
    /* background-color: orange; */
  }
}

@media screen and (min-width: 1024px) {
  .windowOverviewAndImpactTabs {
    width: 900px;
    height: 300px;
    margin-top: 0px;
    /* background-color: green; */
  }
}

@media screen and (min-width: 1200px) {
  .windowOverviewAndImpactTabs {
    width: 592px;
    height: 310px;
    margin-top: 0px;
    /* background-color: yellow; */
  }
}

@media screen and (min-width: 1920px) {
  .windowOverviewAndImpactTabs {
    width: 592px;
    height: 310px;
    margin-top: 0px;
    /* background-color: red; */
  }
}
