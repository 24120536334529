/*===== VARIABLES CSS =====*/
/*=== COLORS ===*/
:root {
  --first-color: #0396a6;
  --dark-color: #1d2130;
  --white-color: #fefdfc;
  --background-white-color: #fefdfc;
  --background-dark-color: #000000;
  --background-dark-green-color: #677865;
  --background-green-color: #cdcdc5;
  --background-gray-color: #eeeeee;
  --text-gay-color: #525560;
  --text-white-color: #ffffff;
  --test-red-color: red;
}
/*=== Fuente y tipografia ===*/
:root {
  --body-font: 'Roboto', sans-serif;
  --biggest-font-size: 4rem;
  --h1-font-size: 56px;
  --h2-font-size: 3rem;
  --h3-font-size: 2rem;
  --h4-font-size: 1.25rem;
  --normal-font-size: 1rem;
}

@media screen and (min-width: 768px) {
  :root {
    --biggest-font-size: 4rem;
    --h1-font-size: 2.5rem;
    --h2-font-size: 2.5rem;
    --h3-font-size: 1.8rem;
    --h4-font-size: 1.2rem;
    --normal-font-size: 1rem;
  }
}

/*===== SECTION ===== */
.lSectionSermonPlaylist {
  background-color: var(--white-color);
}

.sectionSermonPlaylist {
  height: max(100vh, fit-content);
  max-width: 1279px;
  margin-left: 0;
  margin-right: 0;
  padding: 2rem 0;
  color: var(--white-color);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
}
.sectionSermonPlaylistData {
  width: 100%;
}
.sectionCardSermonPlaylist {
  height: max(100vh, fit-content);
  max-width: 1279px;
  margin-left: 0;
  margin-right: 0;
  padding: 2rem 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 10px;
}
.sectionSermonPlaylistDataMarginsTop {
  margin-top: 2%;
}
.containerCardSermonPlaylist {
  padding: 1rem;
  margin-left: auto;
  margin-right: auto;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  box-sizing: border-box;
}
.imageWrapperCardSermonPlaylist {
  position: relative;
  width: 100%;
  max-width: 350px;
  height: 243px;
  overflow: hidden;
  border-radius: 20px;
}
.imageCardSermonPlaylist {
  width: 100%;
  height: 100%;
  background-size: cover;
  background-position: center;
  transition: all 0.3s;
}
.imageBlurCardSermonPlaylist {
  filter: blur(4px);
}
.carouselOverlayCardSermonPlaylist {
  position: absolute;
  inset: 0;
  display: flex;
  flex-direction: column;
  justify-content: end;
  padding: 16px;
  transition: opacity 0.3s;
  z-index: 2;
}
.titleCardSermonPlaylist {
  color: var(--text-white-color);
  font-size: var(--h3-font-size);
  font-weight: bold;
  text-align: center;
}

.buttonCardSermonPlaylist {
  margin-top: 16px;
  background-color: var(--white-color);
  color: var(--dark-color);
  padding: 8px 16px;
  border-radius: 4px;
  opacity: 0;
  transition: opacity 0.3s;
}
.buttonVisibleCardSermonPlaylist {
  opacity: 1;
}
.buttonVisibleCardSermonPlaylist:hover {
  color: var(--white-color);
  background-color: var(--background-dark-green-color);
  transition: color 0.3s ease;
}
.divTextSermonPlaylist {
  color: var(--dark-color);
  font-weight: 600;
  text-align: center;
  margin-top: 20px;
}
.cardSermonPlaylist {
  width: 100%;
  padding: 16px;
  box-sizing: border-box;
}

/*===== MEDIA QUERIES =====*/

@media screen and (min-width: 320px) {
  .titleCardSermonPlaylist {
    font-size: var(--h4-font-size);
  }
}

@media screen and (min-width: 480px) {
  .titleCardSermonPlaylist {
    font-size: var(--h4-font-size);
  }
}

@media screen and (min-width: 760px) {
  .titleCardSermonPlaylist {
    font-size: var(--h3-font-size);
  }
  .sectionCardSermonPlaylist {
    justify-content: center;
    flex-direction: row;
    margin-left: auto;
    margin-right: auto;
  }
  .containerCardSermonPlaylist {
    max-width: 700px;
    display: flex;
    flex-direction: column;
  }
  .cardSermonPlaylist {
    width: 600px;
  }
  .imageWrapperCardSermonPlaylist {
    max-width: 600px;
  }
  .sectionSermonPlaylist {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
    margin-left: auto;
    margin-right: auto;
  }
}

@media screen and (min-width: 1024px) {
  .titleCardSermonPlaylist {
    font-size: var(--h3-font-size);
  }
}

@media screen and (min-width: 1200px) {
  .titleCardSermonPlaylist {
    font-size: var(--h3-font-size);
  }
  .sectionCardSermonPlaylist {
    justify-content: space-between;
    flex-direction: row;
    height: 100%;
    min-height: 200px;
  }
  .containerCardSermonPlaylist {
    display: grid;
    grid-template-columns: repeat(3, minmax(0, 1fr));
    gap: 1rem;
    max-width: 1280px;
    flex-direction: row;
  }
  .cardSermonPlaylist {
    width: 409px;
  }
  .imageWrapperCardSermonPlaylist {
    max-width: 409px;
  }
  .sectionSermonPlaylist {
    height: 100%;
    min-height: 200px;
  }
}

@media screen and (min-width: 1920px) {
  .titleCardSermonPlaylist {
    font-size: var(--h3-font-size);
  }
}
