/*===== VARIABLES CSS =====*/
/*=== COLORS ===*/
:root {
  --first-color: #0396a6;
  --dark-color: #1d2130;
  --white-color: #fefdfc;
  --background-white-color: #fefdfc;
  --background-dark-color: #000000;
  --background-dark-green-color: #677865;
  --background-green-color: #cdcdc5;
  --background-gray-color: #eeeeee;
  --text-gay-color: #525560;
  --text-white-color: #ffffff;
  --test-red-color: red;
}
/*=== Fuente y tipografia ===*/
:root {
  --body-font: 'Roboto', sans-serif;
  --biggest-font-size: 4rem;
  --h1-font-size: 56px;
  --h2-font-size: 3rem;
  --h3-font-size: 2rem;
  --h4-font-size: 1.25rem;
  --normal-font-size: 1rem;
}

@media screen and (min-width: 768px) {
  :root {
    --biggest-font-size: 4rem;
    --h1-font-size: 2.5rem;
    --h2-font-size: 2.5rem;
    --h3-font-size: 1.8rem;
    --normal-font-size: 1rem;
  }
}
/*===== SECTION ===== */
.columGlobalTheGroup {
  background-color: var(--background-dark-color);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 25px;
  width: 100%;
  height: 100%;
}
.divComponentGlobalGroup {
  display: flex;
  justify-content: center;
  margin-bottom: 15px;
}
.divInfoGlobalGroup {
  margin-left: 12px;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
}
.titleInfoGlobalGroup {
  font-size: var(--h4-font-size);
  font-weight: bold;
  color: var(--white-color);
  text-align: center;
}
.textJoinGlobalGroup {
  font-size: var(--normal-font-size);
  color: var(--white-color);
  width: 100%;
  max-width: 290px;
  text-align: center;
}

/*===== IMAGE =====*/

.divImageJoinGlobalGroup {
  width: 100%;
  height: 100%;
  min-height: 160px;
  border-radius: 20px;
  background-size: cover;
  background-position: center;
}
.imageShowAndHidelargerThan1350GlobalGroup {
  display: none;
}
.imageShowAndHidelessThanOrEqualTo1350JoinGlobalGroup {
  display: block;
}

/*===== MEDIA QUERIES =====*/

@media screen and (min-width: 320px) {
  .columGlobalTheGroup {
    padding: 0px;
  }
  .divComponentGlobalGroup {
    justify-content: center;
    /* background-color: green; */
  }
  .divImageJoinGlobalGroup {
    width: 100%;
    min-height: 160px;
    margin-top: 5px;
  }
  .textJoinGlobalGroup {
    max-width: 350px;
  }
  .divInfoGlobalGroup {
    margin-left: 0px;
  }
}

@media screen and (min-width: 480px) {
  .columGlobalTheGroup {
    padding: 0px;
  }
  .divComponentGlobalGroup {
    justify-content: center;
    /* background-color: yellow; */
  }
  .divImageJoinGlobalGroup {
    width: 350px;
    max-width: 450px;
    min-height: 260px;
    margin-top: 5px;
  }
  .divInfoGlobalGroup {
    margin-left: 0px;
  }
}

@media screen and (min-width: 760px) {
  .divInfoGlobalGroup {
    max-height: 130px;
    margin-left: 0px;
  }
  .textJoinGlobalGroup {
    text-align: start;
  }
  .titleInfoGlobalGroup {
    text-align: start;
  }
  .divComponentGlobalGroup {
    justify-content: space-evenly;
  }
  /*===== IMAGE =====*/

  .divImageJoinGlobalGroup {
    width: 300px;
    max-width: 300px;
    min-height: 200px;
    margin-top: 0px;
  }
  .imageShowAndHidelargerThan1350GlobalGroup {
    display: block;
  }
  .imageShowAndHidelessThanOrEqualTo1350JoinGlobalGroup {
    display: none;
  }
}

@media screen and (min-width: 1024px) {
  .divInfoGlobalGroup {
    max-height: 130px;
    margin-left: 0px;
  }

  /*===== IMAGE =====*/
  .divImageJoinGlobalGroup {
    max-width: 300px;
    min-height: 180px;
    margin-top: 0px;
  }
}

@media screen and (min-width: 1200px) {
  .columGlobalTheGroup {
    max-height: 580px;
  }
  .divInfoGlobalGroup {
    max-height: 130px;
    margin-left: 0px;
  }
  .divComponentGlobalGroup {
    margin-bottom: 0px;
  }
  .textJoinGlobalGroup {
    text-align: start;
  }
  /*===== IMAGE =====*/
  .divImageJoinGlobalGroup {
    max-width: 190px;
    min-height: 160px;
    margin-top: 0px;
  }
  .imageShowAndHidelargerThan1350GlobalGroup {
    display: block;
  }
  .imageShowAndHidelessThanOrEqualTo1350JoinGlobalGroup {
    display: none;
  }
}

@media screen and (min-width: 1920px) {
  /*===== IMAGE =====*/
  .divImageJoinGlobalGroup {
    max-width: 190px;
    min-height: 160px;
    margin-top: 0px;
  }
}
