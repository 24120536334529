/*===== VARIABLES CSS =====*/
/*=== COLORS ===*/
:root {
  --first-color: #0396a6;
  --dark-color: #1d2130;
  --white-color: #fefdfc;
  --background-white-color: #fefdfc;
  --background-dark-color: #000000;
  --background-dark-green-color: #677865;
  --background-green-color: #cdcdc5;
  --background-gray-color: #eeeeee;
  --text-gay-color: #525560;
  --text-white-color: #ffffff;
  --test-red-color: red;
}

/*=== Fuente y tipografia ===*/
:root {
  --body-font: 'Roboto', sans-serif;
  --biggest-font-size: 4rem;
  --h1-font-size: 56px;
  --h2-font-size: 3rem;
  --h3-font-size: 2rem;
  --h4-font-size: 1.25rem;
  --normal-font-size: 1rem;
}

@media screen and (min-width: 768px) {
  :root {
    --biggest-font-size: 4rem;
    --h1-font-size: 2.5rem;
    --h2-font-size: 2.5rem;
    --h3-font-size: 1.8rem;
    --normal-font-size: 1rem;
  }
}
/*===== SECTION ===== */
.lSectionDownloadApp {
  background-color: var(--white-color);
  display: flex;
  justify-content: center;
}

.sectionDownloadApp {
  width: 100%;
  max-width: 1279px;
  margin-left: 0;
  margin-right: 0;
  color: var(--white-color);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  padding: 10px;
}
.alignDownloadAppText {
  text-align: center;
}
.sectionDownloadAppData {
  width: 100%;
  max-width: 1240px;
  margin-bottom: 3rem;
}
.customButtonDownloadApp {
  width: 100%;
  max-width: 600px;
  margin: 30px 0 0;
  display: flex;
  justify-content: space-between;
}

/*===== IMAGE =====*/
.sectionDownloadAppImg div {
  background-image: url('../../assets/images/downloadAppTelephone.png');
  background-repeat: no-repeat;
  background-size: cover;
  border-radius: 20px;
  width: 320px;
  height: 340px;
}
.sectionDownloadAppAndroidImg div {
  background-image: url('../../assets/images/downloadAndroindButton.png');
  background-repeat: no-repeat;
  background-size: cover;
  width: 150px;
  height: 55px;
  cursor: pointer;
}
.sectionDownloadAppIOSImg div {
  background-image: url('../../assets/images/downloadIOSButton.png');
  background-repeat: no-repeat;
  background-size: cover;
  width: 150px;
  height: 55px;
  cursor: pointer;
}
.centeredImage {
  display: block;
  margin-left: auto;
  margin-right: auto;
}

/*===== MEDIA QUERIES =====*/

@media screen and (min-width: 1350px) {
  .alignDownloadAppText {
    text-align: left;
  }
  .imageSmallShowAndHideDownloadApp {
    display: none;
  }
  .imageLargeShowAndHideDownloadApp {
    display: block;
  }
  .sectionDownloadAppImg div {
    width: 530px;
    height: 550px;
  }
}

@media screen and (max-width: 1349px) {
  .imageSmallShowAndHideDownloadApp {
    display: flex;
    justify-content: center;
    margin-bottom: 15px;
  }
  .imageLargeShowAndHideDownloadApp {
    display: none;
  }
}

/*===== MEDIA QUERIES =====*/

@media screen and (min-width: 320px) {
  .sectionDownloadApp {
    /* background-color: red; */
  }
  .customButtonDownloadApp {
    max-width: 600px;
    justify-content: space-between;
    /* background-color: yellow; */
  }
}

@media screen and (min-width: 480px) {
  .sectionDownloadApp {
    /* background-color: yellow; */
  }
  .customButtonDownloadApp {
    max-width: 600px;
    justify-content: space-between;
    /* background-color: red; */
  }
}

@media screen and (min-width: 760px) {
  .sectionDownloadApp {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
    margin-left: auto;
    margin-right: auto;
    /* background-color: pink; */
  }
  .customButtonDownloadApp {
    max-width: 760px;
    justify-content: space-evenly;
    /* background-color: red; */
  }
}

@media screen and (min-width: 1024px) {
  .sectionDownloadApp {
    /* background-color: green; */
  }
  .customButtonDownloadApp {
    max-width: 1004px;
    justify-content: space-evenly;
    /* background-color: red; */
  }
}

@media screen and (min-width: 1200px) {
  .sectionDownloadApp {
    height: 700px;
    /* background-color: orange; */
  }
  .sectionDownloadAppData {
    max-width: 600px;
  }
  .customButtonDownloadApp {
    max-width: 600px;
    justify-content: space-between;
    /* background-color: green; */
  }
  .bd-grid {
    margin-left: auto;
    margin-right: auto;
  }
}

@media screen and (min-width: 1920px) {
  .sectionDownloadApp {
    height: 600px;
    /* background-color: blue; */
  }
  .customButtonDownloadApp {
    max-width: 600px;
    justify-content: space-between;
    /* background-color: red; */
  }
}
