/*===== VARIABLES CSS =====*/
/*=== COLORS ===*/
:root {
  --first-color: #0396a6;
  --dark-color: #1d2130;
  --white-color: #fefdfc;
  --background-white-color: #fefdfc;
  --background-dark-color: #000000;
  --background-dark-green-color: #677865;
  --background-green-color: #cdcdc5;
  --background-gray-color: #eeeeee;
  --text-gay-color: #525560;
  --text-white-color: #ffffff;
  --test-red-color: red;
}

/*=== Fuente y tipografia ===*/
:root {
  --body-font: 'Roboto', sans-serif;
  --biggest-font-size: 4rem;
  --h1-font-size: 56px;
  --h2-font-size: 3rem;
  --h3-font-size: 2rem;
  --h4-font-size: 1.25rem;
  --normal-font-size: 1rem;
}

@media screen and (min-width: 768px) {
  :root {
    --biggest-font-size: 4rem;
    --h1-font-size: 2.5rem;
    --h2-font-size: 2.5rem;
    --h3-font-size: 1.8rem;
    --normal-font-size: 1rem;
  }
}
/*===== SECTION ===== */
.lSectionBannerBePartOfTheCommunity {
  background-color: var(--background-white-color);
  display: none;
  -webkit-box-align: center;
  -ms-flex-align: center;
  justify-content: center;
  height: max(100vh, fit-content);
  margin-top: 5%;
  margin-bottom: 5%;
  padding: 10px;
}

.imageWrapperBannerBePartOfTheCommunity {
  position: relative;
  width: 100%;
  height: 384px;
  overflow: hidden;
  border-radius: 20px;
}

.imageBannerBePartOfTheCommunity {
  width: 100%;
  height: 100%;
  background-size: cover;
  background-position: center;
}

.imageBlurAllBannerBePartOfTheCommunity {
  /* filter: blur(1px); */
}

.overlayBannerBePartOfTheCommunity {
  position: absolute;
  inset: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  z-index: 2;
}

.titleBannerBePartOfTheCommunity {
  color: var(--text-white-color);
  font-size: var(--h2-font-size);
  font-weight: bold;
  text-align: center;
  margin-top: 10%;
}
/*===== MEDIA QUERIES =====*/

@media screen and (min-width: 760px) {
  .lSectionBannerBePartOfTheCommunity {
    display: block flex;
    padding: 10px;
  }
  .imageWrapperBannerBePartOfTheCommunity {
    max-width: 1024px;
  }
}

@media screen and (min-width: 1200px) {
  .lSectionBannerBePartOfTheCommunity {
    display: block flex;
    padding: 0px;
  }
  .imageWrapperBannerBePartOfTheCommunity {
    max-width: 1279px;
  }
}
