/*===== VARIABLES CSS =====*/
/*=== COLORS ===*/
:root {
  --first-color: #0396a6;
  --dark-color: #1d2130;
  --white-color: #fefdfc;
  --background-white-color: #fefdfc;
  --background-dark-color: #000000;
  --background-dark-green-color: #677865;
  --background-green-color: #cdcdc5;
  --background-gray-color: #eeeeee;
  --text-gay-color: #525560;
  --text-white-color: #ffffff;
  --test-red-color: red;
}

/*=== Fuente y tipografia ===*/
:root {
  --body-font: 'Roboto', sans-serif;
  --biggest-font-size: 4rem;
  --h1-font-size: 56px;
  --h2-font-size: 3rem;
  --h3-font-size: 2rem;
  --h4-font-size: 1.25rem;
  --normal-font-size: 1rem;
}

@media screen and (min-width: 768px) {
  :root {
    --biggest-font-size: 4rem;
    --h1-font-size: 2.5rem;
    --h2-font-size: 2.5rem;
    --h3-font-size: 1.8rem;
    --normal-font-size: 1rem;
  }
}

/*===== SECTION ===== */
.lSectionCardToContributeProvidingABetterWorld {
  background-color: var(--white-color);
}
.sectionCardToContributeProvidingABetterWorld {
  max-width: 1279px;
  margin-left: 0;
  margin-right: 0;
  padding: 2rem 0;
}
.CardContainerCardToContributeProvidingABetterWorld {
  margin-left: auto;
  margin-right: auto;
  width: 100%;
}
.cardCardToContributeProvidingABetterWorld {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.imageWrapperCardToContributeProvidingABetterWorld {
  position: relative;
  width: 100%;
  max-width: 400px;
  height: 243px;
  overflow: hidden;
  border-radius: 20px;
}
.imageCardToContributeProvidingABetterWorld {
  width: 100%;
  height: 100%;
  background-size: cover;
  background-position: center;
  transition: all 0.3s;
}
.imageBlurCardToContributeProvidingABetterWorld {
  filter: blur(4px);
}
.cardOverlayCardToContributeProvidingABetterWorld {
  position: absolute;
  inset: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 16px;
  transition: opacity 0.3s;
  z-index: 2;
}
.descriptionCardHighlightsAndPreviousSermons {
  color: var(--text-white-color);
  margin-top: 8px;
}
.divContainerButtonsToContributeProvidingABetterWorld {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  width: 100%;
  max-width: 400px;
}
.buttonCardToContributeProvidingABetterWorld {
  margin-top: 16px;
  background-color: var(--white-color);
  color: var(--dark-color);
  padding: 8px 16px;
  border-radius: 4px;
  opacity: 0;
  transition: opacity 0.3s;
}
.buttonVisibleCardToContributeProvidingABetterWorld {
  opacity: 1;
}
.buttonVisibleCardToContributeProvidingABetterWorld:hover {
  color: var(--white-color);
  background-color: var(--background-dark-green-color);
  transition: color 0.3s ease;
}

/*===== MEDIA QUERIES =====*/

@media screen and (min-width: 320px) {
  .cardOverlayCardToContributeProvidingABetterWorld {
    padding: 5px;
  }
  .imageWrapperCardToContributeProvidingABetterWorld {
    max-width: 300px;
  }
}

@media screen and (min-width: 480px) {
  .imageWrapperCardToContributeProvidingABetterWorld {
    max-width: 460px;
  }
}

@media screen and (min-width: 760px) {
  .sectionCardToContributeProvidingABetterWorld {
    justify-content: center;
    flex-direction: row;
    margin-left: auto;
    margin-right: auto;
    height: 380px;
  }
  .CardContainerCardToContributeProvidingABetterWorld {
    max-width: 740px;
  }
  .cardCardToContributeProvidingABetterWorld {
    width: 740px;
    padding: 0px;
  }
  .imageWrapperCardToContributeProvidingABetterWorld {
    max-width: 740px;
    height: 300px;
  }
}

@media screen and (min-width: 1024px) {
  .CardContainerCardToContributeProvidingABetterWorld {
    max-width: 900px;
  }
  .cardCardToContributeProvidingABetterWorld {
    width: 900px;
    padding: 0px;
  }
  .imageWrapperCardToContributeProvidingABetterWorld {
    max-width: 900px;
    height: 300px;
  }
}

@media screen and (min-width: 1200px) {
  .sectionCardToContributeProvidingABetterWorld {
    justify-content: space-between;
    flex-direction: row;
    height: 100%;
    min-height: 390px;
  }
  .descriptionCardHighlightsAndPreviousSermons {
    color: var(--text-white-color);
    margin-top: 8px;
  }
  .CardContainerCardToContributeProvidingABetterWorld {
    display: flex;
    max-width: 1280px;
    flex-direction: row;
  }
  .cardCardToContributeProvidingABetterWorld {
    width: 1280px;
    padding: 0px;
  }
  .imageWrapperCardToContributeProvidingABetterWorld {
    max-width: 1280px;
    height: 390px;
  }
}

/* @media screen and (min-width: 1920px) {
} */
