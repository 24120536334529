/*===== VARIABLES SCSS =====*/
/*===== VARIABLES CSS =====*/
/*=== Cores ===*/
:root {
  --first-color: #0396a6;
  --dark-color: #1d2130;
  --white-color: #fefdfc;
  --background-white-color: #fefdfc;
  --background-dark-color: #000000;
  --background-dark-green-color: #677865;
  --background-green-color: #cdcdc5;
  --background-gray-color: #eeeeee;
  --text-gay-color: #525560;
  --text-white-color: #ffffff;
  --test-red-color: red;
}

/*=== Fuente y tipografia ===*/
:root {
  --body-font: 'Roboto', sans-serif;
  --biggest-font-size: 4rem;
  --h2-font-size: 3rem;
  --normal-font-size: 1rem;
}

@media screen and (min-width: 768px) {
  :root {
    --biggest-font-size: 4rem;
    --h2-font-size: 2.5rem;
    --normal-font-size: 1rem;
  }
}

/*===== SECTION ===== */

.lSectionHowWeUseYourDonation {
  background-color: var(--background-white-color);
  display: flex;
  justify-content: center;
}
.sectionHowWeUseYourDonation {
  width: 100%;
  max-width: 1279px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.sectionHowWeUseYourDonationData {
  width: 100%;
  display: grid;
  grid-template-columns: repeat(1, minmax(0, 1fr));
  gap: 1rem;
}
.divTitleHowWeUseYourDonation {
  width: 100%;
  max-width: 390px;
}
.divTextStylesHowWeUseYourDonation {
  width: 100%;
  max-width: 390px;
  /* background-color: blue; */
}
.textStylesHowWeUseYourDonation {
  font-size: 16px;
  font-weight: regular;
  color: var(--text-gay-color);
  text-align: start;
  margin-bottom: 16px;
}

/*===== MEDIA QUERIES =====*/

@media screen and (min-width: 320px) {
  .sectionHowWeUseYourDonation {
    /* background-color: red; */
    padding: 10px;
  }
  .sectionHowWeUseYourDonationData {
    gap: 0px;
  }
}

@media screen and (min-width: 480px) {
  .sectionHowWeUseYourDonation {
    /* background-color: red; */
    padding: 10px;
  }
  .sectionHowWeUseYourDonationData {
    gap: 0px;
  }
  .divTitleHowWeUseYourDonation {
    max-width: 780px;
  }
  .divTextStylesHowWeUseYourDonation {
    max-width: 500px;
  }
}

@media screen and (min-width: 760px) {
  .sectionHowWeUseYourDonation {
    /* background-color: red; */
    padding: 10px;
  }
  .sectionHowWeUseYourDonationData {
    grid-template-columns: repeat(1, minmax(0, 1fr));
  }
  .divTitleHowWeUseYourDonation {
    max-width: 780px;
  }
  .divTextStylesHowWeUseYourDonation {
    max-width: 780px;
  }
}

@media screen and (min-width: 1024px) {
  .sectionHowWeUseYourDonation {
    /* background-color: red; */
    padding: 0px;
  }
  .sectionHowWeUseYourDonationData {
    grid-template-columns: repeat(1, minmax(0, 1fr));
    max-width: 900px;
  }
  .divTextStylesHowWeUseYourDonation {
    max-width: 900px;
    /* background-color: yellow; */
  }
}

@media screen and (min-width: 1200px) {
  .sectionHowWeUseYourDonation {
    height: 280px;
    padding: 0px;
    /* background-color: red; */
  }
  .sectionHowWeUseYourDonationData {
    grid-template-columns: repeat(3, minmax(0, 1fr));
    max-width: 1279px;
  }
  .divTitleHowWeUseYourDonation {
    max-width: 374px;
  }
  .divTextStylesHowWeUseYourDonation {
    max-width: 400px;
  }
}

@media screen and (min-width: 1920px) {
  .sectionHowWeUseYourDonation {
    padding: 0px;
    /* background-color: red; */
  }
}
