/*===== VARIABLES CSS =====*/
/*=== COLORS ===*/
:root {
  --first-color: #0396a6;
  --dark-color: #1d2130;
  --white-color: #fefdfc;
  --background-white-color: #fefdfc;
  --background-dark-color: #000000;
  --background-dark-green-color: #677865;
  --background-green-color: #cdcdc5;
  --background-gray-color: #eeeeee;
  --text-gay-color: #525560;
  --text-white-color: #ffffff;
  --test-red-color: red;
}
/*=== fONTS ===*/
:root {
  --body-font: 'Roboto', sans-serif;
  --biggest-font-size: 4rem;
  --h1-font-size: 56px;
  --h2-font-size: 3rem;
  --h3-font-size: 2rem;
  --h4-font-size: 1.25rem;
  --normal-font-size: 1rem;
}

@media screen and (min-width: 768px) {
  :root {
    --biggest-font-size: 4rem;
    --h1-font-size: 2.5rem;
    --h2-font-size: 2.5rem;
    --h3-font-size: 1.8rem;
    --normal-font-size: 1rem;
  }
}

/*===== SECTION ===== */
.lSectionCardHighlightsAndPreviousSermons {
  background-color: var(--white-color);
}
.sectionCardHighlightsAndPreviousSermons {
  max-width: 1279px;
  padding: 10px;
}
.CardContainerCardHighlightsAndPreviousSermons {
  width: 100%;
  display: grid;
  grid-template-columns: repeat(1, minmax(0, 1fr));
  gap: 1rem;
}
.cardCardHighlightsAndPreviousSermons {
  width: 100%;
  padding: 16px;
  box-sizing: border-box;
}
.imageWrapperCardHighlightsAndPreviousSermons {
  position: relative;
  width: 100%;
  max-width: 350px;
  height: 243px;
  overflow: hidden;
  border-radius: 20px;
}
.imageCardHighlightsAndPreviousSermons {
  width: 100%;
  height: 100%;
  background-size: cover;
  background-position: center;
  transition: all 0.3s;
}
.imageBlurCardHighlightsAndPreviousSermons {
  filter: blur(4px);
}
.carouselOverlayCardHighlightsAndPreviousSermons {
  position: absolute;
  inset: 0;
  display: flex;
  flex-direction: column;
  justify-content: end;
  padding: 16px;
  transition: opacity 0.3s;
  z-index: 2;
}
.titleCardHighlightsAndPreviousSermons {
  color: var(--text-white-color);
  font-size: var(--h3-font-size);
  font-weight: bold;
  text-align: center;
}
.descriptionCardHighlightsAndPreviousSermons {
  color: var(--text-white-color);
  margin-top: 8px;
}
.buttonCardHighlightsAndPreviousSermons {
  margin-top: 16px;
  background-color: var(--white-color);
  color: var(--dark-color);
  padding: 8px 16px;
  border-radius: 4px;
  opacity: 0;
  transition: opacity 0.3s;
}
.buttonVisibleCardHighlightsAndPreviousSermons {
  opacity: 1;
}
.buttonVisibleCardHighlightsAndPreviousSermons:hover {
  color: var(--white-color);
  background-color: var(--background-dark-green-color);
  transition: color 0.3s ease;
}

/*===== MEDIA QUERIES =====*/

@media screen and (min-width: 320px) {
  .sectionCardHighlightsAndPreviousSermons {
    /* background-color: red; */
    padding: 0px;
  }
  .CardContainerCardHighlightsAndPreviousSermons {
    padding: 0px;
  }
  .cardCardHighlightsAndPreviousSermons {
    padding: 0px;
  }
}

@media screen and (min-width: 480px) {
  .sectionCardHighlightsAndPreviousSermons {
    /* background-color: red; */
  }
  .imageWrapperCardHighlightsAndPreviousSermons {
    width: 100%;
    max-width: 470px;
    min-height: 260px;
    margin-top: 5px;
  }
}

@media screen and (min-width: 760px) {
  .sectionCardHighlightsAndPreviousSermons {
    /* background-color: red; */
  }
  .imageWrapperCardHighlightsAndPreviousSermons {
    max-width: 740px;
  }
  .cardCardHighlightsAndPreviousSermons {
    width: 740px;
  }
}

@media screen and (min-width: 1024px) {
  .sectionCardHighlightsAndPreviousSermons {
    /* background-color: red; */
  }
  .CardContainerCardHighlightsAndPreviousSermons {
    grid-template-columns: repeat(3, minmax(0, 1fr));
  }
  .imageWrapperCardHighlightsAndPreviousSermons {
    max-width: 320px;
  }
  .cardCardHighlightsAndPreviousSermons {
    width: 320px;
  }
}

@media screen and (min-width: 1200px) {
  .sectionCardHighlightsAndPreviousSermons {
    justify-content: space-between;
    flex-direction: row;
    height: 100%;
    min-height: 200px;
    /* background-color: red; */
  }
  .descriptionCardHighlightsAndPreviousSermons {
    color: var(--text-white-color);
    margin-top: 8px;
  }
  .CardContainerCardHighlightsAndPreviousSermons {
    display: flex;
    max-width: 1280px;
    flex-direction: row;
  }
  .imageWrapperCardHighlightsAndPreviousSermons {
    max-width: 409px;
  }
  .cardCardHighlightsAndPreviousSermons {
    width: 409px;
  }
  .titleCardHighlightsAndPreviousSermons {
    font-size: var(--h2-font-size);
  }
}

@media screen and (min-width: 1920px) {
  .sectionCardHighlightsAndPreviousSermons {
    /* background-color: red; */
  }
}
