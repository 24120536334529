/*===== VARIABLES CSS =====*/
/*=== COLORS ===*/
:root {
  --first-color: #0396a6;
  --dark-color: #1d2130;
  --white-color: #fefdfc;
  --background-white-color: #fefdfc;
  --background-dark-color: #000000;
  --background-dark-green-color: #677865;
  --background-green-color: #cdcdc5;
  --background-gray-color: #eeeeee;
  --text-gay-color: #525560;
  --text-white-color: #ffffff;
  --test-red-color: red;
}

/*=== Fuente y tipografia ===*/
:root {
  --body-font: 'Roboto', sans-serif;
  --biggest-font-size: 4rem;
  --h1-font-size: 56px;
  --h2-font-size: 3rem;
  --h3-font-size: 2rem;
  --h4-font-size: 1.25rem;
  --normal-font-size: 1rem;
}

@media screen and (min-width: 768px) {
  :root {
    --biggest-font-size: 4rem;
    --h1-font-size: 2.5rem;
    --h2-font-size: 2.5rem;
    --h3-font-size: 1.8rem;
    --normal-font-size: 1rem;
  }
}

/*=== z index ===*/
:root {
  --z-back: -10;
  --z-normal: 1;
  --z-tooltip: 10;
  --z-fixed: 100;
  --z-modal: 1000;
}

/*===== Customização dos botões =====*/
*,
::before,
::after {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

.buttonPrimary {
  color: var(--white-color);
  background-color: var(--background-dark-green-color);
  transition: color 0.3s ease;
  border-radius: 4px;
}

.buttonPrimary:hover {
  color: var(--dark-color);
  background-color: var(--background-green-color);
  transition: color 0.3s ease;
}

.buttonSecondary {
  color: var(--dark-color);
  background-color: var(--background-white-color);
  transition: color 0.3s ease;
  border-radius: 4px;
}

.buttonSecondary:hover {
  color: var(--white-color);
  background-color: var(--background-green-color);
  transition: color 0.3s ease;
}

/*===== BACK GROUND PAGE WHITE=====*/
.buttonbackgroundPageWhite {
  color: var(--white-color);
  background-color: var(--background-dark-green-color);
  transition: color 0.3s ease;
  border-radius: 4px;
}

.buttonbackgroundPageWhite:hover {
  color: var(--dark-color);
  background-color: var(--background-gray-color);
  transition: color 0.3s ease;
}
