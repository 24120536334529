/*===== VARIABLES CSS =====*/
/*=== COLORS ===*/
:root {
  --first-color: #0396a6;
  --dark-color: #1d2130;
  --white-color: #fefdfc;
  --background-white-color: #fefdfc;
  --background-dark-color: #000000;
  --background-dark-green-color: #677865;
  --background-green-color: #cdcdc5;
  --background-gray-color: #eeeeee;
  --text-gay-color: #525560;
  --text-white-color: #ffffff;
  --test-red-color: red;
}
/*=== Fuente y tipografia ===*/
:root {
  --body-font: 'Roboto', sans-serif;
  --biggest-font-size: 4rem;
  --h1-font-size: 56px;
  --h2-font-size: 3rem;
  --h3-font-size: 2rem;
  --h4-font-size: 1.25rem;
  --normal-font-size: 1rem;
}

@media screen and (min-width: 768px) {
  :root {
    --biggest-font-size: 4rem;
    --h1-font-size: 2.5rem;
    --h2-font-size: 2.5rem;
    --h3-font-size: 1.8rem;
    --normal-font-size: 1rem;
  }
}

/*===== SECTION ===== */
.lSectionWatchServicesAndEventsOnline {
  background-color: var(--white-color);
}

.sectionWatchServicesAndEventsOnline {
  max-width: 1279px;
  margin-left: 0;
  margin-right: 0;
  color: var(--white-color);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
}
.sectionWatchServicesAndEventsOnlineData {
  width: 100%;
  margin-top: 90px;
}
.marginTopCustomizedHighlightsAndPreviousSermons {
  margin-top: 200px;
}
.customDivButtonWatchServicesAndEventsOnline {
  margin: 30px 0 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}
.sectionTextDarkWatchServicesAndEventsOnline {
  color: var(--text-gay-color);
}

/*===== MEDIA QUERIES =====*/

@media screen and (min-width: 320px) {
  .sectionWatchServicesAndEventsOnline {
    /* background-color: red; */
  }
  .sectionWatchServicesAndEventsOnlineData {
    /* background-color: yellow; */
    margin-top: 80px;
  }
}

@media screen and (min-width: 480px) {
  .sectionWatchServicesAndEventsOnline {
    /* background-color: yellow; */
  }
  .sectionWatchServicesAndEventsOnlineData {
    /* background-color: red; */
    margin-top: 80px;
  }
}

@media screen and (min-width: 760px) {
  .sectionWatchServicesAndEventsOnline {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
    margin-left: auto;
    margin-right: auto;
    /* background-color: green; */
  }
  .sectionWatchServicesAndEventsOnlineData {
    /* background-color: red; */
    margin-top: 90px;
  }
  .sectionTextDarkWatchServicesAndEventsOnline {
    padding-right: 4rem;
  }
}

@media screen and (min-width: 1024px) {
  .sectionWatchServicesAndEventsOnline {
    /* background-color: pink; */
  }
  .sectionWatchServicesAndEventsOnlineData {
    /* background-color: red; */
    margin-top: 90px;
  }
}

@media screen and (min-width: 1200px) {
  .sectionWatchServicesAndEventsOnline {
    height: 100%;
    min-height: 200px;
    /* background-color: green; */
  }
  .sectionWatchServicesAndEventsOnlineData {
    /* background-color: red; */
    margin-top: 90px;
  }
  .customDivButtonWatchServicesAndEventsOnline {
    margin: 30px 0 0;
    justify-content: left;
  }
}

@media screen and (min-width: 1920px) {
  .sectionWatchServicesAndEventsOnline {
    /* background-color: green; */
  }
  .sectionWatchServicesAndEventsOnlineData {
    /* background-color: red; */
    margin-top: 90px;
  }
}
