/*===== VARIABLES CSS =====*/
/*=== COLORS ===*/
:root {
  --first-color: #0396a6;
  --dark-color: #1d2130;
  --white-color: #fefdfc;
  --background-white-color: #fefdfc;
  --background-dark-color: #000000;
  --background-dark-green-color: #677865;
  --background-green-color: #cdcdc5;
  --background-gray-color: #eeeeee;
  --text-gay-color: #525560;
  --text-white-color: #ffffff;
  --test-red-color: red;
}

/*=== Fuente y tipografia ===*/
:root {
  --body-font: 'Roboto', sans-serif;
  --biggest-font-size: 4rem;
  --h1-font-size: 56px;
  --h2-font-size: 3rem;
  --h3-font-size: 2rem;
  --h4-font-size: 1.25rem;
  --normal-font-size: 1rem;
}

@media screen and (min-width: 768px) {
  :root {
    --biggest-font-size: 4rem;
    --h1-font-size: 2.5rem;
    --h2-font-size: 2.5rem;
    --h3-font-size: 1.8rem;
    --normal-font-size: 1rem;
  }
}
/*===== SECTION ===== */
.lSectionTheImportanceOfTithesAndOfferings {
  background-color: var(--background-green-color);
  display: flex;
  justify-content: center;
}
.sectionTheImportanceOfTithesAndOfferings {
  width: 100%;
  max-width: 1279px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
}
.sectionTheImportanceOfTithesAndOfferingsData {
  margin-bottom: 3rem;
  margin-top: 5rem;
}
.TheImportanceOfTithesAndOfferings {
  margin: 30px 0 0;
  display: flex;
  justify-content: center;
}

/*===== IMAGE =====*/

.containerDivTheImportanceOfTithesAndOfferings {
  margin-top: 10px;
  width: 100%;
  max-width: 727px;
  display: flex;
  justify-content: center;
  text-align: center;
}
.containerDivTextTheImportanceOfTithesAndOfferings {
  margin-top: 10px;
  width: 100%;
  max-width: 727px;
  display: flex;
  justify-content: center;
  text-align: center;
}

/*===== MEDIA QUERIES =====*/

@media screen and (min-width: 320px) {
  .sectionTheImportanceOfTithesAndOfferings {
    /* background-color: red; */
  }
}

@media screen and (min-width: 480px) {
  .sectionTheImportanceOfTithesAndOfferings {
    /* background-color: red; */
  }
}

@media screen and (min-width: 760px) {
  .sectionTheImportanceOfTithesAndOfferings {
    /* background-color: red; */
  }
  .sectionTheImportanceOfTithesAndOfferingsImg div {
    justify-content: center;
    width: 470px;
    height: 470px;
  }
  .containerDivTheImportanceOfTithesAndOfferings {
    max-width: 900px;
    margin-top: 10px;
    text-align: start;
  }
  .containerDivTextTheImportanceOfTithesAndOfferings {
    max-width: 900px;
    margin-top: 10px;
    text-align: start;
  }
}

@media screen and (min-width: 1024px) {
  .sectionTheImportanceOfTithesAndOfferings {
    height: 100%;
    /* background-color: red; */
  }
}

@media screen and (min-width: 1200px) {
  .sectionTheImportanceOfTithesAndOfferings {
    /* background-color: red; */
    flex-direction: row;
    justify-content: space-between;
    padding: 0px;
  }
  .containerDivTheImportanceOfTithesAndOfferings {
    max-width: 727px;
    margin-top: 0px;
    justify-content: start;
    text-align: start;
  }
  .containerDivTextTheImportanceOfTithesAndOfferings {
    max-width: 600px;
    margin-top: 0px;
    justify-content: start;
    text-align: start;
  }
}

@media screen and (min-width: 1920px) {
  .sectionTheImportanceOfTithesAndOfferings {
    /* background-color: red; */
    height: 500px;
  }
}
