/*===== VARIABLES CSS =====*/
/*=== COLORS ===*/
:root {
  --first-color: #0396a6;
  --dark-color: #1d2130;
  --white-color: #fefdfc;
  --background-white-color: #fefdfc;
  --background-dark-color: #000000;
  --background-dark-green-color: #677865;
  --background-green-color: #cdcdc5;
  --background-gray-color: #eeeeee;
  --text-gay-color: #525560;
  --text-white-color: #ffffff;
  --test-red-color: red;
}

/*=== Fuente y tipografia ===*/
:root {
  --body-font: 'Roboto', sans-serif;
  --biggest-font-size: 4rem;
  --h1-font-size: 56px;
  --h2-font-size: 3rem;
  --h3-font-size: 2rem;
  --h4-font-size: 1.25rem;
  --normal-font-size: 1rem;
}

@media screen and (min-width: 768px) {
  :root {
    --biggest-font-size: 4rem;
    --h1-font-size: 2.5rem;
    --h2-font-size: 2.5rem;
    --h3-font-size: 1.8rem;
    --normal-font-size: 1rem;
  }
}
/*===== SECTION ===== */
.lSectionCommunityMission {
  background-color: var(--white-color);
  display: flex;
  justify-content: center;
}
.sectionCommunityMission {
  width: 100%;
  max-width: 1279px;
  color: var(--white-color);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
}
.sectionCommunityMissionData {
  margin-bottom: 3rem;
}
.customButtonCommunityMission {
  margin: 30px 0 0;
  display: flex;
  justify-content: center;
}

/*===== IMAGE =====*/
.sectionCommunityMissionImg div {
  background-image: url('assets/images/redencaoSimbolo.jpg');
  background-repeat: no-repeat;
  background-size: cover;
  border-radius: 20px;
  width: 320px;
  height: 320px;
}
.centeredImage {
  display: block;
  margin-left: auto;
  margin-right: auto;
}
.containerDivCommunityMission {
  max-width: 727px;
  /* background-color: red; */
}

/*===== IMAGES =====*/
.imageSmallShowAndHideCommunityMission {
  display: block;
}
.imageLargeShowAndHideCommunityMission {
  display: none;
}

/*===== MEDIA QUERIES =====*/
@media screen and (min-width: 320px) {
  .sectionCommunityMission {
    /* background-color: red; */
  }
  .containerDivCommunityMission {
    max-width: 320px;
    /* background-color: yellow; */
  }
  .sectionCommunityMissionImg div {
    justify-content: center;
    width: 300px;
    height: 300px;
  }
}

@media screen and (min-width: 480px) {
  .sectionCommunityMission {
    /* background-color: red; */
  }
  .containerDivCommunityMission {
    max-width: 480px;
    /* background-color: green; */
  }
  .sectionCommunityMissionImg div {
    justify-content: center;
    width: 450px;
    height: 450px;
  }
}

@media screen and (min-width: 760px) {
  .containerDivCommunityMission {
    max-width: 760px;
    /* background-color: orange; */
  }
  .sectionCommunityMissionImg div {
    justify-content: center;
    width: 470px;
    height: 470px;
  }
  .sectionCommunityMission {
    flex-direction: row;
    justify-content: center;
    padding: 10px;
    /* background-color: green; */
  }
  .imageSmallShowAndHideCommunityMission {
    display: block;
  }
  .imageLargeShowAndHideCommunityMission {
    display: none;
  }
}

@media screen and (min-width: 1024px) {
  .sectionCommunityMission {
    /* background-color: red; */
  }
  .containerDivCommunityMission {
    max-width: 1000px;
  }
}

@media screen and (min-width: 1200px) {
  .containerDivCommunityMission {
    max-width: 1200px;
    /* background-color: pink; */
  }
  .sectionCommunityMission {
    height: 550px;
    justify-content: space-between;
    padding: 0px;
    /* background-color: red; */
  }
  .customButtonCommunityMission {
    justify-content: left;
  }
  .imageSmallShowAndHideCommunityMission {
    display: none;
  }
  .imageLargeShowAndHideCommunityMission {
    display: block;
  }
  .containerDivCommunityMission {
    margin-top: 0px;
  }
}

@media screen and (min-width: 1920px) {
  .sectionCommunityMission {
    height: 650px;
    /* background-color: red; */
  }
  .containerDivCommunityMission {
    max-width: 800px;
  }
}
