/*===== VARIABLES CSS =====*/
/*=== COLORS ===*/
:root {
  --first-color: #0396a6;
  --dark-color: #1d2130;
  --white-color: #fefdfc;
  --background-white-color: #fefdfc;
  --background-dark-color: #000000;
  --background-dark-green-color: #677865;
  --background-green-color: #cdcdc5;
  --background-gray-color: #eeeeee;
  --text-gay-color: #525560;
  --text-white-color: #ffffff;
  --test-red-color: red;
}

/*=== Fuente y tipografia ===*/
:root {
  --body-font: 'Roboto', sans-serif;
  --biggest-font-size: 4rem;
  --h1-font-size: 56px;
  --h2-font-size: 3rem;
  --h3-font-size: 2rem;
  --h4-font-size: 1.25rem;
  --normal-font-size: 1rem;
}

@media screen and (min-width: 768px) {
  :root {
    --biggest-font-size: 4rem;
    --h1-font-size: 2.5rem;
    --h2-font-size: 2.5rem;
    --h3-font-size: 1.8rem;
    --h4-font-size: 1.2rem;
    --normal-font-size: 1rem;
  }
}

/*===== SECTION ===== */
.lSectionAfterTheService {
  background-color: var(--white-color);
}
.sectionAfterTheService {
  max-width: 1279px;
  margin-left: 0;
  margin-right: 0;
  padding: 2rem 0;
  color: var(--dark-color);
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: space-between;
  -ms-flex-pack: space-between;
  justify-content: space-between;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  padding: 10px;
}
.sectionAfterTheServiceData {
  margin-bottom: 3rem;
}
.customButtonAfterTheService {
  margin: 30px 0 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}
.columInfoAfterTheService {
  background-color: var(--background-green-color);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 25px;
  border-radius: 20px;
  width: 100%;
  max-width: 590px;
  height: 100%;
}
.divComponentInfo {
  /* display: flex; */
  margin-bottom: 15px;
}
.divInfoAfterTheService {
  margin-left: 12px;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
}
.titleInfoAfterTheService {
  font-weight: bold;
  color: var(--text-dark-blue-color);
  text-align: center;
}
.subTitleInfoAfterTheService {
  font-size: 0.87rem;
  font-weight: bold;
  color: var(--text-dark-blue-color);
  opacity: 50%;
  text-align: center;
}
.textInfoAfterTheService {
  font-size: var(--normal-font-size);
  color: var(--text-gay-color);
  width: 100%;
  max-width: 290px;
  text-align: center;
}

/*===== IMAGE =====*/

.divImageInfoAfterTheService {
  width: 100%;
  height: 100%;
  min-height: 160px;
  border-radius: 20px;
  background-size: cover;
  background-position: center;
}
.imageShowAndHidelargerThan1350AfterTheService {
  display: none;
}
.imageShowAndHidelessThanOrEqualTo1350AfterTheService {
  display: block;
}

/*===== MEDIA QUERIES =====*/

@media screen and (min-width: 320px) {
  .sectionAfterTheService {
    /* background-color: red; */
  }
  .divComponentInfo {
    display: Center;
    /* background-color: pink; */
  }
  .titleInfoAfterTheService {
    font-size: var(--h4-font-size);
  }
  .divInfoAfterTheService {
    margin-left: 0px;
  }
  .textInfoAfterTheService {
    max-width: 100%;
  }
}

@media screen and (min-width: 480px) {
  .sectionAfterTheService {
    /* background-color: red; */
  }
  .divComponentInfo {
    display: Center;
    /* background-color: yellow; */
  }
  .titleInfoAfterTheService {
    font-size: var(--h3-font-size);
  }
}

@media screen and (min-width: 760px) {
  .sectionAfterTheService {
    flex-direction: column;
    margin-left: auto;
    margin-right: auto;
    /* background-color: red; */
  }
  .divComponentInfo {
    display: flex;
    /* background-color: blue; */
  }
  .titleInfoAfterTheService {
    font-size: var(--h3-font-size);
    text-align: start;
  }
  .divInfoAfterTheService {
    margin-left: 12px;
    max-height: 130px;
  }
  .subTitleInfoAfterTheService {
    text-align: start;
  }
  .textInfoAfterTheService {
    text-align: start;
  }
  /*===== IMAGE =====*/

  .divImageInfoAfterTheService {
    max-width: 190px;
  }
  .imageShowAndHidelargerThan1350AfterTheService {
    display: block;
  }
  .imageShowAndHidelessThanOrEqualTo1350AfterTheService {
    display: none;
  }
}

@media screen and (min-width: 1024px) {
  .sectionAfterTheService {
    /* background-color: red; */
  }
  .divComponentInfo {
    display: Center;
    /* background-color: green; */
  }
  .titleInfoAfterTheService {
    font-size: var(--h3-font-size);
    text-align: start;
  }
}

@media screen and (min-width: 1200px) {
  .sectionAfterTheService {
    height: 620px;
    max-width: 1279px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 0px;
    /* background-color: red; */
  }
  .divComponentInfo {
    display: Center;
    /* background-color: purple; */
  }
  .titleInfoAfterTheService {
    font-size: var(--h3-font-size);
    text-align: start;
  }
  .customButtonAfterTheService {
    margin: 30px 0 0;
    justify-content: left;
  }
  .divContainerAfterTheService {
    max-width: 580px;
  }
  .columInfoAfterTheService {
    max-height: 580px;
  }
  .divComponentInfo {
    margin-bottom: 0px;
  }
  .divInfoAfterTheService {
    max-height: 130px;
  }
  .subTitleInfoAfterTheService {
    text-align: start;
  }
  .textInfoAfterTheService {
    text-align: start;
  }
  /*===== IMAGE =====*/
  .divImageInfoAfterTheService {
    max-width: 190px;
  }
  .imageShowAndHidelargerThan1350AfterTheService {
    display: block;
  }
  .imageShowAndHidelessThanOrEqualTo1350AfterTheService {
    display: none;
  }
}

@media screen and (min-width: 1920px) {
  .sectionAfterTheService {
    height: 620px;
    /* background-color: red; */
  }
  .divComponentInfo {
    display: Center;
    /* background-color: pink; */
  }
  .titleInfoAfterTheService {
    font-size: var(--h3-font-size);
    text-align: start;
  }
}
