/*===== VARIABLES CSS =====*/
/*=== COLORS ===*/
:root {
  --first-color: #0396a6;
  --dark-color: #1d2130;
  --white-color: #fefdfc;
  --background-white-color: #fefdfc;
  --background-dark-color: #000000;
  --background-dark-green-color: #677865;
  --background-green-color: #cdcdc5;
  --background-gray-color: #eeeeee;
  --text-gay-color: #525560;
  --text-white-color: #ffffff;
  --test-red-color: red;
}

/*=== Fuente y tipografia ===*/
:root {
  --body-font: 'Roboto', sans-serif;
  --biggest-font-size: 4rem;
  --h1-font-size: 56px;
  --h2-font-size: 3rem;
  --h3-font-size: 2rem;
  --h4-font-size: 1.25rem;
  --normal-font-size: 1rem;
}

@media screen and (min-width: 768px) {
  :root {
    --biggest-font-size: 4rem;
    --h1-font-size: 2.5rem;
    --h2-font-size: 2.5rem;
    --h3-font-size: 1.8rem;
    --normal-font-size: 1rem;
  }
}

/*===== SECTION ===== */
.lSectionHowToContribute {
  background-color: var(--background-white-color);
  display: flex;
  justify-content: center;
  align-items: center;
  height: max(100vh, fit-content);
  padding: 10px;
}
.containerHowToContribute {
  width: 100%;
  max-width: 1279px;
  margin-left: 0;
  margin-right: 0;
  display: row;
  margin-top: 10px;
}
.sectionAlignTextHowToContribute {
  text-align: center;
}
.divContainerHowToContribute {
  max-width: 500px;
  display: flex;
  text-align: start;
  justify-content: center;
}
.sectionTextDarkHowToContribute {
  color: var(--text-gay-color);
  width: 100%;
  max-width: 727px;
}
.columTabHowToContribute {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.lineBottonHowToContribute {
  margin-top: 40px;
  width: 100%;
  height: 1px;
  max-width: 1279px;
  background-color: var(--background-green-color);
}

/*===== MEDIA QUERIES =====*/

@media screen and (min-width: 320px) {
  .sectionTextDarkHowToContribute {
    margin-top: 0px;
    /* background-color: red; */
  }
}

@media screen and (min-width: 480px) {
  .sectionTextDarkHowToContribute {
    margin-top: 0px;
    /* background-color: red; */
  }
}

@media screen and (min-width: 760px) {
  .containerHowToContribute {
    margin-top: 40px;
  }
  .sectionColumHowToContribute {
    display: grid;
    grid-template-columns: repeat(1, minmax(0, 1fr));
    gap: 1rem;
  }
  .lineBottonHowToContribute {
    margin-top: 40px;
  }
  .sectionAlignTextHowToContribute {
    text-align: center;
    margin-top: 0px;
    /* background-color: red; */
  }
  .divContainerHowToContribute {
    max-width: 100%;
  }
  .sectionTextDarkHowToContribute {
    margin-top: 0px;
    /* background-color: red; */
    max-width: 900px;
  }
}

@media screen and (min-width: 1024px) {
  .sectionAlignTextHowToContribute {
    text-align: center;
    margin-top: 0px;
    /* background-color: red; */
  }
}

@media screen and (min-width: 1200px) {
  .containerHowToContribute {
    margin-top: 70px;
  }
  .sectionColumHowToContribute {
    grid-template-columns: repeat(2, minmax(0, 1fr));
    gap: 1rem;
  }
  .sectionAlignTextHowToContribute {
    text-align: start;
    margin-top: 0px;
    /* background-color: red; */
  }
  .divContainerHowToContribute {
    max-width: 592px;
  }
  .lineBottonHowToContribute {
    margin-top: 90px;
  }
}

@media screen and (min-width: 1920px) {
  .sectionAlignTextHowToContribute {
    text-align: start;
    margin-top: 0px;
    /* background-color: red; */
  }
}
