/*===== VARIABLES CSS =====*/
/*=== COLORS ===*/
:root {
  --first-color: #0396a6;
  --dark-color: #1d2130;
  --white-color: #fefdfc;
  --background-white-color: #fefdfc;
  --background-dark-color: #000000;
  --background-dark-green-color: #677865;
  --background-green-color: #cdcdc5;
  --background-gray-color: #eeeeee;
  --text-gay-color: #525560;
  --text-white-color: #ffffff;
  --test-red-color: red;
}

/*=== Fuente y tipografia ===*/
:root {
  --body-font: 'Roboto', sans-serif;
  --biggest-font-size: 4rem;
  --h1-font-size: 56px;
  --h2-font-size: 3rem;
  --h3-font-size: 2rem;
  --h4-font-size: 1.25rem;
  --normal-font-size: 1rem;
}

@media screen and (min-width: 768px) {
  :root {
    --biggest-font-size: 4rem;
    --h1-font-size: 2.5rem;
    --h2-font-size: 2.5rem;
    --h3-font-size: 1.8rem;
    --normal-font-size: 1rem;
  }
}

/*===== SECTION ===== */
.lSectionLocationMaps {
  background-color: var(--white-color);
}
.sectionLocationMaps {
  height: max(100vh, fit-content);
  margin-top: 30px;
  margin-bottom: 30px;
}

/*===== MEDIA QUERIES =====*/

@media screen and (min-width: 760px) {
  .sectionLocationMaps {
    margin-top: 70px;
    margin-bottom: 70px;
  }
}

@media screen and (min-width: 1200px) {
  .sectionLocationMaps {
    margin-top: 70px;
    margin-bottom: 70px;
  }
}
