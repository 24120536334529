/*===== VARIABLES CSS =====*/
/*=== COLORS ===*/
:root {
  --first-color: #0396a6;
  --dark-color: #1d2130;
  --white-color: #fefdfc;
  --background-white-color: #fefdfc;
  --background-dark-color: #000000;
  --background-dark-green-color: #677865;
  --background-green-color: #cdcdc5;
  --background-gray-color: #eeeeee;
  --text-gay-color: #525560;
  --text-white-color: #ffffff;
  --test-red-color: red;
}

/*=== Fuente y tipografia ===*/
:root {
  --body-font: 'Roboto', sans-serif;
  --biggest-font-size: 4rem;
  --h1-font-size: 56px;
  --h2-font-size: 3rem;
  --h3-font-size: 2rem;
  --h4-font-size: 1.25rem;
  --normal-font-size: 1rem;
}

@media screen and (min-width: 768px) {
  :root {
    --biggest-font-size: 4rem;
    --h1-font-size: 2.5rem;
    --h2-font-size: 2.5rem;
    --h3-font-size: 1.8rem;
    --normal-font-size: 1rem;
  }
}

/*===== SECTION ===== */
.lSectionMissionAndValues {
  background-color: var(--background-green-color);
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  padding: 10px;
}
.containerMissionAndValues {
  width: 100%;
  max-width: 1279px;
  margin-left: 0;
  margin-right: 0;
  padding: 2rem 0;
  display: row;
}
.MarginTopColumMissionAndValues {
  margin-top: 20px;
}
.SubTitleAbountUs {
  margin-bottom: 1rem;
  font-weight: bold;
  font-size: var(--h3-font-size);
}

/*===== MEDIA QUERIES =====*/

@media screen and (min-width: 760px) {
  .sectionColumMissionAndValues {
    display: grid;
    grid-template-columns: repeat(1, minmax(0, 1fr));
    gap: 1rem;
  }
}

@media screen and (min-width: 1200px) {
  .sectionColumMissionAndValues {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }
  .MarginTopColumMissionAndValues {
    margin-top: 0px;
  }
  .SubTitleAbountUs {
    margin-bottom: 1rem;
    font-weight: bold;
    font-size: 2.3rem;
  }
}
