/*===== VARIABLES CSS =====*/
/*=== COLORS ===*/
:root {
  --first-color: #0396a6;
  --dark-color: #1d2130;
  --white-color: #fefdfc;
  --background-white-color: #fefdfc;
  --background-dark-color: #000000;
  --background-dark-green-color: #677865;
  --background-green-color: #cdcdc5;
  --background-gray-color: #eeeeee;
  --text-gay-color: #525560;
  --text-white-color: #ffffff;
  --test-red-color: red;
}

/*=== Fuente y tipografia ===*/
:root {
  --body-font: 'Roboto', sans-serif;
  --biggest-font-size: 4rem;
  --h1-font-size: 56px;
  --h2-font-size: 3rem;
  --h3-font-size: 2rem;
  --h4-font-size: 1.25rem;
  --normal-font-size: 1rem;
}

@media screen and (min-width: 768px) {
  :root {
    --biggest-font-size: 4rem;
    --h1-font-size: 2.5rem;
    --h2-font-size: 2.5rem;
    --h3-font-size: 1.8rem;
    --normal-font-size: 1rem;
  }
}

.sliderContainerCarouselEventsAndParties {
  margin-left: auto;
  margin-right: auto;
  width: 100%;
  max-width: 630px;
}
.carouselCardCarouselEventsAndParties {
  width: 100%;
  padding: 1rem;
  box-sizing: border-box;
  /* background-color: red; */
}
.carouselImageWrapperCarouselEventsAndParties {
  position: relative;
  width: 100%;
  max-width: 628px;
  height: 200px;
  overflow: hidden;
  border-radius: 20px;
  /* background-color: yellow; */
}
.carouselImageCarouselEventsAndParties {
  width: 100%;
  height: 100%;
  background-size: cover;
  background-position: center;
  transition: all 0.3s;
  /* filter: blur(2px); */
  /* background-color: blue; */
}
.carouselImageBlurCarouselEventsAndParties {
  filter: blur(4px);
}
.carouselOverlayCarouselEventsAndParties {
  position: absolute;
  inset: 0;
  display: flex;
  flex-direction: column;
  justify-content: end;
  padding: 16px;
  transition: opacity 0.3s;
  z-index: 2;
  background-color: purple;
}
.divContainerGridEventsAndParties {
  display: flex;
  align-items: center;
  text-align: center;
  /* background-color: pink; */
}
.divContainerDataAndMonthEventsAndParties {
  width: 100%;
  max-width: 80px;
  /* background-color: red; */
}
.titleEventDataEventsAndParties {
  font-weight: 500;
  font-size: 48px;
  height: 60px;
  /* background-color: orange; */
}
.titleEventMonthEventsAndParties {
  font-weight: 500;
}
.divContainerEventNameEventsAndParties {
  width: 100%;
  display: flex;
  justify-content: start;
  /* background-color: green; */
}
.carouselTitleCarouselEventsAndParties {
  font-size: var(--h3-font-size);
  font-weight: bold;
}

.carouselDescriptionCarouselEventsAndParties {
  margin-top: 8px;
  /* background-color: yellow; */
}

.slick-prev,
.slick-next {
  background-color: transparent; /* Altere para a cor desejada */
  border-radius: 50%; /* Ajuste o formato do botão */
  width: 40px; /* Ajuste o tamanho do botão */
  height: 40px; /* Ajuste o tamanho do botão */
}

.slick-prev::before,
.slick-next::before {
  font-size: 24px; /* Ajuste o tamanho do ícone */
  color: var(--background-dark-green-color); /* Cor do ícone */
}

.carouselButtonEventsAndParties {
  margin-top: 16px;
  background-color: var(--white-color);
  color: var(--dark-color);
  border-radius: 4px;
  opacity: 0;
  transition: opacity 0.3s;
}
.carouselButtonVisibleEventsAndParties {
  opacity: 1;
}
.carouselButtonVisibleEventsAndParties:hover {
  color: var(--white-color);
  background-color: var(--background-dark-green-color);
  transition: color 0.3s ease;
}

/*===== MEDIA QUERIES =====*/

@media screen and (min-width: 320px) {
  .sliderContainerCarouselEventsAndParties {
    max-width: 270px;
    padding: 0px;
    /* background-color: orange; */
  }
  .carouselImageWrapperCarouselEventsAndParties {
    max-width: 628px;
    height: 330px;
  }
}

@media screen and (min-width: 480px) {
  .sliderContainerCarouselEventsAndParties {
    max-width: 430px;
    padding: 0px;
    /* background-color: pink; */
  }
  .carouselImageWrapperCarouselEventsAndParties {
    max-width: 628px;
    height: 230px;
  }
}

@media screen and (min-width: 760px) {
  .sliderContainerCarouselEventsAndParties {
    max-width: 700px;
    padding: 0px;
    /* background-color: orange; */
  }
  .carouselCardCarouselEventsAndParties {
    width: 100%;
    /* background-color: pink; */
  }
  .carouselButtonEventsAndParties {
    padding: 4px;
  }
}

@media screen and (min-width: 1024px) {
  .sliderContainerCarouselEventsAndParties {
    max-width: 900px;
    padding: 0px;
    /* background-color: blue; */
  }
  .carouselButtonEventsAndParties {
    padding: 8px 16px;
  }
}

@media screen and (min-width: 1200px) {
  .sliderContainerCarouselEventsAndParties {
    max-width: 1280px;
    padding: 1rem;
    /* background-color: blue; */
  }
  .carouselImageWrapperCarouselEventsAndParties {
    max-width: 628px;
    height: 230px;
  }
  .carouselCardCarouselEventsAndParties {
    width: 33.3333%;
    /* background-color: blue; */
  }
  .carouselButtonEventsAndParties {
    padding: 8px 16px;
  }
}

@media screen and (min-width: 1920px) {
}
